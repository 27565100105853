<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-layout class="patient-list" column>
    <v-card class="rounded-xl overflow-hidden" :disabled="disableWhileLoading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ getpatientN + "s" }}</div>
        <div class="mx-4 mt-4 pt-1">
          <v-switch class="font-weight-bold white--text" label="No Relationship" :loading="disableWhileLoading"
            :disabled="disableWhileLoading" inset @change="getPatients" v-model="noCoordinator"></v-switch>
        </div>
        <div class="mt-4 mx-4 pt-1">
          <v-switch inset color="white" @change="getPatients()" v-model="cotizedOnly" :loading="loading"
            :label="`Quoted`"></v-switch>
        </div>
        <v-spacer />
        <div class="mx-4 mt-4 pt-2">
          <v-select :items="lang" v-model="preferLanguage" item-text="label" item-value="value" :loading="loading"
            outlined @change="getPatients" dense label="by Language"></v-select>
        </div>
        <div>
          <v-autocomplete prepend-inner-icon="mdi-magnify" hide-details clearable outlined @change="getPatients()" dense
            class="mx-2" :items="references" item-text="name" item-value="id" v-model="sourceId" label="by Source">
            <!--  <template v-slot:prepend-item>
              <v-text-field
                class="px-2"
                prepend-inner-icon="mdi-magnify-plus-outline"
                placeholder="Custom source"
                clearable
                @click:clearable="getPatients()"
                @change="getPatients()"
                v-model="sourceId"
              ></v-text-field>
            </template> -->
            <!--   <template v-slot:selection>
                <span>{{ body.howKnow }}</span>
              </template> -->
          </v-autocomplete>
        </div>

        <template v-if="selected.length != 0">
          <v-dialog persistent v-model="transferDialog" transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon color="#76FF03" v-on="{ ...dialog, ...tooltip }">
                    <v-icon>mdi-account-network</v-icon></v-btn>
                </template>
                <span>Create Relationship</span>
              </v-tooltip>
            </template>
            <template>
              <v-card>
                <v-toolbar color="primary" dark>Select Employee</v-toolbar>
                <v-card-text>
                  <div class="text-h2 pt-4 px-4 d-flex">
                    <v-select v-model="employeeUuidt" label="Employees" :items="workers" prepend-inner-icon="mdi-doctor"
                      item-text="fullname" item-value="uuid" :loading="loadingOcup" hide-details clearable outlined
                      dense />
                    <v-select class="ml-1" :disabled="employeeUuidt == '' || employeeUuidt == null"
                      v-model="relation.ocupationId" label="Occupations" :items="ocupations" item-text="name"
                      item-value="id" :loading="loadingOcup" hide-details outlined dense />
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closedTransfer">Close</v-btn>
                  <v-btn :disabled="employeeUuidt == '' ||
                    employeeUuidt == null ||
                    relation.ocupationId == null ||
                    loadingAction
                    " color="primary" :loading="loadingAction" text @click="transfer">Confirm</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
        <template v-if="selected.length != 0">
          <v-dialog persistent v-model="deleteDialog" transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on: dialog }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn icon color="error" v-on="{ ...dialog, ...tooltip }">
                    <v-icon>mdi-close-network</v-icon></v-btn>
                </template>
                <span>Delete Relationship</span>
              </v-tooltip>
            </template>
            <template>
              <v-card>
                <v-toolbar color="error" dark>Select Employees</v-toolbar>
                <v-card-text>
                  <div class="text-h2 pt-4 px-4 d-flex">
                    <v-autocomplete v-model="employeeUuidDel" label="Select Agents" :items="workers"
                      prepend-inner-icon="mdi-doctor" item-text="fullname" item-value="uuid" :loading="loadingOcup"
                      hide-details multiple clearable outlined dense>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.rol"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="closedTransfer">Close</v-btn>
                  <v-btn :disabled="employeeUuidDel.length == 0 || loadingAction" color="error" :loading="loadingAction"
                    text @click="deleteRelation">Delete</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
        <template v-if="selected.length != 0">
          <asign-status :clientsUuids="selected" @updated="toGePAtient" />
        </template>

        <v-btn :loading="loading" text @click="getPatients">
          <v-icon>mdi-autorenew</v-icon>
          {{ $t("labelUpdate") }}
        </v-btn>
      </v-toolbar>

      <div class="d-flex align-center pa-3">
        {{/* Filter */ }}

        <v-text-field v-model.trim="query" :label="'Search'" hide-details prepend-inner-icon="mdi-magnify" clearable
          outlined dense />
        <v-text-field dense outlined class="ml-1" hide-details label="by Policy Number" prepend-inner-icon="mdi-magnify"
          v-model="polizeNumber" clearable></v-text-field>


        <template v-if="isAdmins">
          <v-autocomplete v-model="agentUuid" label="by Agent" :items="workers" class="mx-1"
            prepend-inner-icon="mdi-doctor" item-text="fullname" item-value="uuid" :loading="loading" hide-details
            clearable outlined dense @change="getPatients">
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                <v-list-item-subtitle v-if="data.item.ocupation" class="text-capitalize"
                  v-html="data.item.ocupation.name"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </template>

        <v-select v-model="companyId" label="by Company" :items="companies"
          prepend-inner-icon="mdi-briefcase-variant-outline" item-text="name" :class="!isAdmins ? 'ml-1' : ''"
          item-value="id" hide-details clearable outlined dense @change="getPatients" />
        <v-select v-model="companyStateId" label="by Company State" class="mx-1" :items="states"
          prepend-inner-icon="mdi-magnify" item-text="name" :loading="loadingStates" item-value="id" hide-details
          clearable outlined dense @change="getPatients" />
        <v-select v-model="tagId" label="by Tags" :items="tags" prepend-inner-icon="mdi-magnify" item-text="name"
          item-value="id" class="ml-1" hide-details clearable outlined dense @change="getPatients" />

        <!--  <v-select
          v-model="policyPlan"
          label="Filter by Metal Plan"
          :items="metalplan"
          prepend-inner-icon="mdi-magnify"
          item-text="label"
          item-value="value"
          class="ml-1"
          hide-details
          clearable
          outlined
          dense
          @change="getPatients"
        /> -->
        <v-select v-model="polizeStatus" label="by Status" :items="statesf" prepend-inner-icon="mdi-magnify"
          item-text="label" item-value="value" class="mx-1" hide-details clearable outlined dense
          @change="getPatients" />
        <div class="d-flex pt-4">
          <template v-if="filterDate == 'custom'">
            <ma-date-picker class="pt-2 pr-1" v-model="dateFrom" label="From" past />
            <ma-date-picker class="pt-2" v-model="dateTo" label="To" past />
          </template>
        </div>
        <template>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" icon><v-icon>mdi-filter</v-icon></v-btn>
              </template>

              <v-list width="140" nav dense>
                <v-list-item v-for="interval in intervals" :key="interval.value" link
                  @click="setIntervar(interval.value)">
                  <v-list-item-title>{{ interval.label }}</v-list-item-title>
                </v-list-item>
                <v-divider />

                <v-list-item link @click="filterDate = 'custom'">
                  <v-list-item-title>Select</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="filterDate = 'clear'">
                  <v-list-item-title class="red--text">Clean</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn :loading="donwloading" :disabled="donwloading" v-on="on" icon color="success"
                @click="gotoExport()"><v-icon>mdi-file-excel</v-icon></v-btn>
            </template>
            <span>Export {{ getpatientN + "s" }}</span>
          </v-tooltip>
        </template>
        <v-dialog v-model="dialogExport" persistent :overlay="false" max-width="800px" transition="dialog-transition">
          <v-card>
            <v-card-title class="headline primary white--text">
              Export
            </v-card-title>
            <v-card-text class="mt-3">
              <div class="text-center">
                <h3>Select the columns you want to export:</h3>
              </div>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <v-checkbox label="Select All" v-model="checkAll" color="success" :value="true"></v-checkbox>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="3" v-for="(check, i) in selectColumnExport" :key="i">
                  <v-switch inset :label="check == 'Company_State' ? 'Company State' : check" v-model="checkToExport"
                    :value="check"></v-switch>
                </v-col>
              </v-row>
              <template v-if="donwloading">
                <v-alert dense outlined type="warning" class="text-center mt-4">
                  Exporting...Please wait!!
                </v-alert>
              </template>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn text :disabled="donwloading" color="error" @click="cancelExport()">cancel</v-btn>
              <v-btn text :disabled="donwloading" :loading="donwloading" color="success"
                @click="exportClients()">export</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-divider />
      <template v-if="filterDate != 'clear'">
        <v-layout class="pa-4" column>
          <div class="mr-2 text-capitalize">
            {{ filterDate.replace("-", " ") }}
          </div>
          <v-layout column class="text-body-1 text--secondary">
            <div>
              From
              <span class="text-caption">{{ prettyDate(query1.date1) }}</span>
            </div>
            <div>
              To
              <span class="text-caption">{{ prettyDate(query1.date2) }}</span>
            </div>
          </v-layout>
        </v-layout>
        <v-divider></v-divider>
      </template>
      <v-data-table v-model="selected" :headers="headers" :options.sync="options" :server-items-length="totalpatient"
        :items="patients" :show-select="isAdmins" :loading="disableWhileLoading" :footer-props="footerProps"
        item-key="uuid" @click:row="showDetails">
        <template v-slot:[`item.fullname`]="{ item }">
          <span class="namepatient">{{ item.fullname }} </span>
        </template>
        <template v-slot:[`item.relations`]="{ item }">
          <template v-if="item.relations && item.relations.length != 0">
            <div class="d-flex flex-column">
              <div class="mr-1" v-for="(r, i) in item.relations" :key="r.id">
                {{ r.employee ? r.employee.fullname : "-"
                }}{{ i == item.relations.length - 1 ? "" : ", " }}
              </div>
            </div>
          </template>
          <template v-else> - </template>
        </template>

        <template v-slot:[`header.tagNom`]="{ header }">
          {{ `${header.text}` }}
          <!-- <v-icon color="info" @click="showSourceSummary">mdi-eye</v-icon> -->
        </template>

        <template v-slot:[`item.tagNom`]="{ item }">
          <span v-bind:style="{ color: colorShowT(item) }" class="font-weight-bold">{{ item.tagNom ? item.tagNom.name :
            "-" }}</span>
        </template>

        <template v-slot:[`item.company`]="{ item }">
          {{ item.company ? item.company.name : "-" }}
        </template>
        <template v-slot:[`item.quotedDate`]="{ item }">
          <template v-if="item.quotedDate">
            {{ getDate(item.quotedDate) }}
          </template>
          <template v-else>
            <v-icon color="error" dense>mdi-close</v-icon>
          </template>
        </template>
        <template v-slot:[`item.companyState`]="{ item }">
          {{ item.companyState ? item.companyState.name : "-" }}
        </template>
        <template v-slot:[`item.call`]="{ item }">
          <template v-if="item.calls.length == 0">
            <v-icon color="error">mdi-close</v-icon>
          </template>
          <template v-else><v-icon color="success">mdi-check</v-icon></template>
        </template>
        <template v-slot:[`item.members`]="{ item }">
          <div>
            {{ item.members ? item.members : 0 }}
          </div>
        </template>
        <template v-slot:[`item.policyPlan`]="{ item }">
          <div class="font-weight-bold" v-bind:style="{ color: colorPolicy(item.policyPlan) }">
            {{ item.policyPlan }}
          </div>
        </template>
        <template v-slot:[`item.agent`]="{ item }">
          {{ getAgent(item) }}
        </template>
        <template v-slot:[`item.sourceNom`]="{ item }">
          <span v-bind:style="{ color: colorShowS(item) }" class="font-weight-bold">{{ item.sourceNom ?
            item.sourceNom.name : "-" }}</span>
        </template>
        <template v-slot:[`item.polizeStatus`]="{ item }">
          <v-menu bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="text-body-1 cursor-pointer" v-bind:style="{ color: colorShow(item) }">
                {{ statusShow(item) }}
              </div>
            </template>
            <v-list>
              <v-list-item class="cursor-pointer" v-for="(stat, index) in policyStates" :key="index"
                @click="gotosetStatus(item, stat)" :disabled="item.polizeStatus == stat.value">
                <v-list-item-title :class="item.polizeStatus == stat.value ? 'status-active' : ''
                  ">{{ stat.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:[`header.members`]="{ header }">
          {{ `${header.text}(${totalMembers})` }}
        </template>
        <template v-slot:[`header.polizeStatus`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="success" @click="showStatusSummary">mdi-eye</v-icon>
        </template>
        <template v-slot:[`header.sourceNom`]="{ header }">
          {{ `${header.text}`
          }}<v-icon color="info" @click="showSourceSummary">mdi-eye</v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.quotedDate">
            <v-tooltip transition="fade-transition" color="info" top>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon v-on="on"><v-icon>mdi-currency-usd</v-icon></v-btn>
              </template>

              <v-row class="toolt" no-gutters>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Quoted Date</label>
                  <span>{{
                    item.quotedDate ? getDate(item.quotedDate) : "-"
                  }}</span>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Company</label>
                  <span>{{ item.company.name }}</span>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Members</label>
                  <span class="px-1">{{ item.members }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-4 toolt">
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Metal Plan</label>

                  <span class="text-body-1 font-weight-bold" v-bind:style="{
                    color: colorPolicy(item.policyPlan),
                  }">{{ item.policyPlan }}</span>
                </v-col>
                <v-col cols="12" sm="4" class="d-flex flex-column">
                  <label class="font-weight-bold" for="">Premium</label>
                  <span>{{ item.premium | currency }}</span>
                </v-col>
              </v-row>
            </v-tooltip>
          </template>
        </template>
        <not-records slot="no-data" />
      </v-data-table>
      <v-dialog v-model="setStatusObject.dialog" persistent :overlay="false" max-width="500px"
        transition="dialog-transition">
        <v-card>
          <v-card-title class="primary white--text">
            <template v-if="setStatusObject.value && setStatusObject.item">
              Status Change:
              {{
                statusShow(setStatusObject.item) +
                " to " +
                setStatusObject.value.label
              }}
            </template>
          </v-card-title>
          <v-card-text>
            <v-textarea v-model.trim="setStatusObject.note" class="my-3" placeholder="Write a note" :disabled="loading"
              outlined counter="240" />
          </v-card-text>
          <template v-if="!validNote">
            <div class="mx-3">
              <v-alert dense outlined type="error">
                The note must be between 5 and 240 characters
              </v-alert>
            </div>
          </template>

          <v-card-actions class="d-flex justify-end">
            <v-btn color="error" :disabled="setStatusObject.loading" text @click="cancelSetStatus">cancel</v-btn>
            <v-btn color="success" :loading="setStatusObject.loading" :disabled="setStatusObject.loading || !validNote"
              text @click="setStatus">Change</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showStatus.dialog" :overlay="false" max-width="500px" transition="dialog-transition">
        <v-card>
          <template>
            <v-data-table :headers="showStatus.headers" :items="showStatus.data" :items-per-page="10"
              class="elevation-1">
              <template v-slot:[`item.status`]="{ item }">
                <div class="text-body-1 cursor-pointer" v-bind:style="{ color: colorShow(item) }">
                  {{ statusShow(item) }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showSource.dialog" :overlay="false" max-width="500px" transition="dialog-transition">
        <v-card>
          <template>
            <v-data-table :headers="showSource.headers" :items="showSource.data" :items-per-page="10"
              class="elevation-1">
              <template v-slot:[`item.source`]="{ item }">
                <div class="text-body-1 cursor-pointer font-weight-bold" v-bind:style="{ color: colorSourceEye(item) }">
                  {{ item.source }}
                </div>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-dialog>
      <v-layout justify-center>
        <errors v-model="showErrors" class="mt-3" :message="errorMessage" />
      </v-layout>
    </v-card>

    {{/* Confirm remove */ }}
  </v-layout>
</template>

<script>
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import Errors from "../PdfSign/Errors.vue";

import BtnRequestPatients from "./buttons/BtnRequestPatients.vue";

import { PolicyStatus } from "@/utils/enums/policystatus";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import cleanObject from "@/utils/cleanObject";
import AsignStatus from "@/views/Patients/components/AsignStatus.vue";
import { utilMixin } from "@/utils/mixins";
import { cleanPhoneNumber, isPhoneNumber } from "@/utils/formatPhone";
export default Vue.extend({
  name: "crm-patients",
  components: {
    NotRecords,
    Errors,
    BtnRequestPatients,
    MaDatePicker,
    AsignStatus,
    /*   PatientFilter, */
  },
  mixins: [utilMixin],
  data() {
    return {
      dateTo: null,
      dateFrom: null,
      filterDate: "clear",
      transferDialog: false,
      deleteDialog: false,
      dialogExport: false,
      checkToExport: [],
      showStatus: {
        dialog: false,
        data: [],
        headers: [
          { text: "Status", value: "status", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
        ],
      },
      showSource: {
        dialog: false,
        data: [],
        headers: [
          { text: "Source", value: "source", sortable: false },
          { text: "Policies", value: "count_policies", sortable: false },
        ],
      },
      patients: [],
      totalpatient: 0,
      loading: false,
      checkAll: false,
      selectColumnExport: [
        "Name",
        "Last Name",
        "DOB",
        "Address",
        "Company",
        "MemberID",
        "Seller",
        "Notes",
        "Payments",
        "Members",
        "Phone",
        "Company_State",
      ],
      loadingOcup: false,
      loadingAction: false,
      ocupations: [],
      lang: [
        { label: "All", value: null },
        { label: "English", value: "ENGLISH" },
        { label: "Spanish", value: "SPANISH" },
      ],
      employeeUuidt: "",
      employeeUuidDel: [],
      relation: {
        employeeUuid: "",
        ocupationId: null,
        patientUuids: [],
      },
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],

      signature: new Blob(),
      loadingSignStep1: false,
      showErrors: false,
      options: {
        sortDesc: [false],
      },
      reqrange: {
        limit: 100,
        offset: 0,
      },
      errorMessage: "",

      loadingSendStep: false,
      footerProps: {
        "items-per-page-options": [15, 50, 100, 500],
      },
      selected: [],
      expanded: [],
      // The uuid of the worker to remove
      currentPatient: null,
      dialogEdit: false,
      workers: [],
      dialogDocument: false,
      dialogSignStep1: false,
      loadingState: false,
      query1: {
        date1: "",
        date2: "",
      },
      toExport: null,
      donwloading: false,

      query: null,
      totalMembers: 0,
      totalPolicies: 0,
      loadingSummary: false,
      polizeStatus: null,
      policyPlan: null,
      polizeNumber: null,
      preferLanguage: null,
      sourceId: null,
      companyId: null,
      tagId: null,
      companyStateId: null,
      noCoordinator: false,
      sortAscending: false,
      employeeUuid: null,
      agentUuid: null,
      cotizedOnly: false,
      createdInterval: {
        date1: "",
        date2: "",
      },
      loadingStatus: false,
      setStatusObject: {
        dialog: false,
        value: null,
        item: null,
        loading: false,
        note: "",
      },
      isAdmins: false,

      statesf: [
        { value: "POLICY_NEW", label: "Policy New" },
        { value: "POLIZE_PENDING", label: "Policy Pending" },
        { value: "POLIZE_OK", label: "Policy Ok" },
        {
          value: "POLIZE_MEMBERS_NO_MATCH",
          label: "Policy Member does not match",
        },
        {
          value: "PAYMENT_MEMBERS_NO_MATCH",
          label: "Member payment does not match",
        },
        { value: "PAYMENT_OK", label: "Payment Ok" },
        { value: "PAYMENT_PENDING", label: "Payment Pending" },
        { value: "POLICY_CANCELLATION", label: "Cancelled" },
        { value: "POLICY_RENEWAL", label: "Renewal" },
        { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
        { value: "OTHER_PARTY", label: "Other Party" },
        { value: "NO_AOR", label: "No Aor" },
        { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
        { value: "DELINCUENT", label: "Delinquent" },
        { value: "POLICY_BLACKLIST", label: "Blacklist" },
        { value: "CHARGE_BACK", label: "Charge Back" },
        { value: "PENDING_RENEWAL", label: "Pending Renewal" },
        { value: "NO_RENEWAL", label: "No Renewal" },
        { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
      ],
      intervals: [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "This week", value: "this-week" },
        { label: "Last week", value: "last-week" },
        { label: "This month", value: "this-month" },
        { label: "Last month", value: "last-month" },
        { label: "This year", value: "this-year" },
        { label: "Last year", value: "last-year" },
      ],
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", [
      "doctors",
      "agents",
      "states",
      "loadingStates",
    ]),

    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getRequireNote",
      "isSupervisor",
      "getCoordinatorN",
      "getSellerN",
      "getpatientN",
      "getviewpatientStatus",
    ]),

    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "companies",
      "references",
      "tags",
    ]),

    validNote() {
      if (
        this.setStatusObject.note == "" ||
        this.setStatusObject.note == null ||
        this.setStatusObject.note == undefined
      ) {
        return false;
      }
      if (
        this.setStatusObject.note.length < 5 ||
        this.setStatusObject.note.length > 240
      ) {
        return false;
      }
      return true;
    },

    disableWhileLoading() {
      return this.loading || this.loadingSignStep1 || this.loadingSendStep;
    },

    policyStates() {
      const statesf = this.isAdmins
        ? [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ]
        : [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },

            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ];
      return statesf;
    },

    headers() {
      return [
        { text: this.$t("Fullname"), value: "fullname", sortable: true },
       
        {
          text: this.$t("Agent"),
          value: "relations",
          width: 200,
          sortable: false,
        },
        { text: this.$t("Company"), value: "company", sortable: false },
        /*  { text: this.$t("labelGender"), value: "isMale", sortable: false },
          { text: "Email", value: "email", align: "start", sortable: false },
          {
            text: this.$t("labelHomePhone"),
            value: "homePhone",
            align: "start",
            sortable: false,
          },*/
        { text: "Company State", value: "companyState", sortable: false },
        { text: "Source", value: "sourceNom", sortable: false },
        { text: "Tag", value: "tagNom", sortable: false },
        {
          text: "Quote Date",
          value: "quotedDate",
          sortable: false,
          align: "center",
        },
        {
          text: "Members",
          value: "members",
          align: "center",
          sortable: false,
        },
        { text: "Status", value: "polizeStatus", sortable: false },
        { text: "With Check", value: "call", sortable: false },
        { text: "Quote", value: "actions", sortable: false },
      ];

      /* return [
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("Company"), value: "company", sortable: false },
        { text: this.$t("Agent"), value: "agent", sortable: false },
       { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "start", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "start",
          sortable: false,
        },
      ];*/
    },

    intervalToday() {
      const from = moment().startOf("day");
      const to = moment();
      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarYesterDay() {
      const from = moment().subtract(1, "days").startOf("day");
      const to = moment().subtract(1, "days").endOf("day");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisWeek() {
      const from = moment().startOf("week");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastWeek() {
      const from = moment().subtract(1, "week").startOf("week");
      const to = moment().subtract(1, "week").endOf("week");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisMonth() {
      const from = moment().startOf("month");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastMonth() {
      const month = moment().month() + 1;

      // Si coincide con enero retornar el mes actual
      if (month == 1) {
        return this.intervarThisMonth;
      }

      const from = moment().subtract(1, "month").startOf("month");
      const to = moment().subtract(1, "month").endOf("month");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarThisYear() {
      const from = moment().startOf("year");
      const to = moment();

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
    intervarLastYear() {
      const from = moment().subtract(1, "year").startOf("year");
      const to = moment().subtract(1, "year").endOf("year");

      return {
        date1: from.toISOString(),
        date2: to.toISOString(),
      };
    },
  },
  watch: {
    filterDate: {
      handler() {
        if (this.filterDate != "custom") {
          this.getPatients();
        }
      },
    },

    checkAll(val) {
      if (val == true) {
        this.checkToExport = this.selectColumnExport;
      } else {
        this.checkToExport = [];
      }
    },
    employeeUuidt(val) {
      if (val != "" && val != null) {
        const worker = this.workers.filter((w) => w.uuid == val)[0];
        this.relation.ocupationId = worker.ocupation
          ? worker.ocupation.id
          : null;
        this.relation.employeeUuid = val;
      } else {
        this.relation.ocupationId = null;
        this.relation.employeeUuid = "";
      }
    },
    options(val) {
      if (val != {}) {
        if (val.sortDesc[0] != undefined) {
          this.sortAscending = val.sortDesc[0];
          this.getPatients();
        }
      }
    },
    query(val) {
      if (val == null) {
        this.getPatients();
      } else if (val.length >= 3 || val.length == 0) {
        if (isPhoneNumber(val)) {
          this.query = cleanPhoneNumber(val);
          if (this.query[0] == "1") {
            this.query = this.query.slice(1);
          }
        }
        this.getPatients();
      }
    },
    polizeNumber(val) {
      if (val == null) {
        this.getPatients();
      } else if (val.length >= 5 || val.length == 0) {
        this.getPatients();
      }
    },

    dateFrom(val) {
      if (val != null && this.dateTo != null && this.dateTo != "") {
        this.getPatients();
      }
    },
    dateTo(val) {
      if (val != null && this.dateFrom != null && this.dateFrom != "") {
        this.getPatients();
      }
    },
  },
  destroyed() {
    localStorage.removeItem("filterfrompatients");
  },
  async mounted() {
    localStorage.removeItem("tab-patient");

    this.actListCompaniesNom();
    this.actListReferences();
    this.actListTags();
    await this.actListStates();

    await this.getEmployees();

    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
      this.loadingOcup = true;
      await this.listOcupations();
      this.loadingOcup = false;
    }
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actFilterPatient", "actListStates"]),

    ...mapActions("crmSettingsModule", [
      "actListCompaniesNom",
      "actListReferences",
      "actListTags",
    ]),
    ...mapMutations(["mutReqRange"]),
    gotoExport() {
      this.dialogExport = true;
      this.checkToExport = [];
      this.checkAll = false;
    },
    cancelExport() {
      this.dialogExport = false;
      this.checkToExport = [];
      this.checkAll = false;
    },

    async listOcupations() {
      this.ocupations = (await getAPI(`/ocupation-nom/list`)).data;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },

    setIntervar(value) {
      this.filterDate = value;
    },

    getEmployees() {
      getAPI("/users/listUsers").then((res) => {
        this.workers = res.data;
      });
    },
    toGePAtient() {
      this.polizeStatus = null;
      this.getPatients();
    },

    showStatusSummary() {
      this.showStatus.dialog = true;
    },
    showSourceSummary() {
      this.showSource.dialog = true;
    },
    getSummary(value) {
      this.loadingSummary = true;
      delete value.range;
      getAPI
        .post("/patient/summaryFiltrPatient", value)
        .then((res) => {
          this.loadingSummary = false;
          const response = res.data;
          const t = response.find((r) => r.status == "TOTAL");
          const data = response.filter(
            (r) => r.status != "TOTAL" && r.source == null
          );
          const datas = response.filter(
            (r) => r.status != "TOTAL" && r.source != null
          );
          this.showStatus.data = data;
          this.showSource.data = datas;
          this.totalMembers = t.count_members;
          this.totalPolicies = t.count_policies;
        })
        .catch((error) => {
          this.loadingSummary = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getPatients() {
      this.selected = [];

      if (
        localStorage.getItem("filterfrompatients") &&
        localStorage.getItem("filtersPatients")
      ) {
        const tempdata = JSON.parse(localStorage.getItem("filtersPatients"));
        this.options.page = tempdata.page;
        this.options.itemsPerPage = tempdata.itemsPerPage;
        this.query = tempdata.query;
        this.polizeStatus = tempdata.polizeStatus;
        this.cotizedOnly = tempdata.cotizedOnly;
        this.preferLanguage = tempdata.preferLanguage;
        this.sourceId = tempdata.sourceId;
        this.tagId = tempdata.tagId;
        this.noCoordinator = tempdata.noCoordinator;
        this.employeeUuid = tempdata.employeeUuid;
        this.polizeNumber = tempdata.polizeNumber;
        this.sortAscending = tempdata.sortAscending;
        this.companyId = tempdata.companyId;
        this.companyStateId = tempdata.companyStateId;
        this.agentUuid = tempdata.agentUuid;
        this.createdInterval = tempdata.createdInterval;
      }

      switch (this.filterDate) {
        case "today":
          this.createdInterval = this.intervalToday;
          break;
        case "yesterday":
          this.createdInterval = this.intervarYesterDay;
          break;
        case "this-week":
          this.createdInterval = this.intervarThisWeek;
          break;
        case "last-week":
          this.createdInterval = this.intervarLastWeek;
          break;
        case "this-month":
          this.createdInterval = this.intervarThisMonth;
          break;
        case "last-month":
          this.createdInterval = this.intervarLastMonth;
          break;
        case "this-year":
          this.createdInterval = this.intervarThisYear;
          break;
        case "last-year":
          this.createdInterval = this.intervarLastYear;
          break;
        case "custom":
          if (this.dateFrom == null || this.dateTo == null) {
            this.createdInterval = {
              date1: "",
              date2: "",
            };
          } else {
            this.createdInterval = {
              date1: moment(this.dateFrom).startOf("day").toISOString(),
              date2: moment(this.dateTo).endOf("day").toISOString(),
            };
          }
          break;
        case "clear":
          this.createdInterval = {
            date1: "",
            date2: "",
          };
          this.dateFrom = null;
          this.dateTo = null;
          break;
      }

      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.reqrange = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      this.mutReqRange(this.reqrange);

      localStorage.setItem(
        "filtersPatients",
        JSON.stringify({
          query: this.query,
          polizeStatus: this.polizeStatus,
          employeeUuid: this.employeeUuid,
          polizeNumber: this.polizeNumber,
          cotizedOnly: this.cotizedOnly,
          sortAscending: this.sortAscending,
          companyId: this.companyId,
          companyStateId: this.companyStateId,
          preferLanguage: this.preferLanguage,
          sourceId: this.sourceId,
          tagId: this.tagId,
          agentUuid: this.agentUuid,
          createdInterval: this.createdInterval,
          noCoordinator: this.noCoordinator,
          page,
          itemsPerPage,
        })
      );
      localStorage.removeItem("filterfrompatients");

      let body = {
        query: this.query,
        polizeStatus: this.polizeStatus,
        range: this.reqrange,
        polizeNumber: this.polizeNumber,
        cotizedOnly: this.cotizedOnly,
        preferLanguage: this.preferLanguage,
        sourceId: this.sourceId,
        tagId: this.tagId,
        sortAscending: this.sortAscending,
        companyId: this.companyId,
        companyStateId: this.companyStateId,
        employeeUuid: this.employeeUuid,
        noCoordinator: this.noCoordinator,
        agentUuid: this.agentUuid,
        createdInterval: this.createdInterval,
      };
      this.query1 = this.createdInterval;

      if (this.filterDate == "clear") {
        delete body.createdInterval;
        this.query1 = { date1: "", date2: "" };
      }
      if (
        this.filterDate == "custom" &&
        (this.createdInterval.date1 == "" || this.createdInterval.date2 == "")
      ) {
        delete body.createdInterval;
        this.query1 = { date1: "", date2: "" };
      }

      this.toExport = body;
      this.loading = true;

      if (body.query == "") {
        delete body.query;
      }
      if (body.polizeNumber == "") {
        delete body.polizeNumber;
      }
      body = cleanObject(body);
      getAPI
        .post("/patient/filterPatients", body)
        .then((res) => {
          this.loading = false;
          this.patients = res.data.result;
          this.totalpatient = res.data.count;
          this.getSummary(body);
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    setPatientToSendDocument(patient) {
      this.currentPatient = patient;
      this.dialogDocument = true;
    },
    sendStep(step, patientUuid) {
      this.loadingSendStep = true;
      getAPI.post(`/patient/sendstep${step}/${patientUuid}`).then(() => {
        notifyInfo("The documents have been Sent");
        this.loadingSendStep = false;
      });
    },
    showSignDialog(patient) {
      this.signature = new Blob();
      this.currentPatient = patient;
      this.dialogSignStep1 = true;
    },
    signAdminStep1() {
      const formData = new FormData();

      this.loadingSignStep1 = true;
      formData.append(
        "firma",
        this.signature,
        `signature-${this.currentPatient.uuid}`
      );
      getAPI({
        method: "post",
        url: "/patient/uploadAdminSign/" + this.currentPatient.uuid,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);

          this.loadingSignStep1 = false;
          this.dialogSignStep1 = false;
        });
    },
    showDetails(patient) {
      this.$router.push(`/clients/details/${patient.uuid}`);
    },

    transfer() {
      this.loadingAction = true;
      const body = Object.assign({}, this.relation);
      const patientTransfer = this.selected.map((patient) => patient.uuid);
      body.patientUuids = patientTransfer;
      body.employeeUuid = this.employeeUuidt;
      getAPI
        .post("/patient/createMultipleRelations", body)
        .then(() => {
          notifySuccess(this.$t("Patients Relationship has been created"));
          this.getPatients();
          this.closedTransfer();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    deleteRelation() {
      this.loadingAction = true;
      const body = Object.assign({}, this.relation);
      const patientTransfer = this.selected.map((patient) => patient.uuid);
      body.employeeUuid = this.employeeUuidDel;

      body.patientUuids = patientTransfer;
      getAPI
        .post("/patient/deleteMultipleRelations", {
          employeeUuids: body.employeeUuid,
          patientUuids: body.patientUuids,
        })
        .then(() => {
          notifyInfo(this.$t("Patients Relationship has been deleted"));
          this.getPatients();
          this.closedTransfer();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loadingAction = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    closedTransfer() {
      this.transferDialog = false;
      this.deleteDialog = false;
      this.employeeUuidt = "";
      this.loadingOcup = false;
      this.loadingAction = false;
      this.relation = {
        employeeUuid: "",
        ocupationId: null,
        patientUuids: [],
      };
    },

    clearDateInterval() {
      this.dateFrom = null;
      this.dateTo = null;
      this.getPatients();
    },
    colorShowS(item) {
      return item.sourceNom ? item.sourceNom.color : "#000";
    },
    colorSourceEye(data) {
      if (this.references.length != 0) {
        const x = this.references.find((r) => r.name == data.source);
        return x ? x.color : "#000";
      }
      return "#000";
    },
    colorShowT(item) {
      return item.tagNom ? item.tagNom.color : "#000";
    },
    colorShow(item) {
      const status = item.polizeStatus ? item.polizeStatus : item.status;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "CHARGE_BACK":
            return "#9366C6";
          case "POLICY_BLACKLIST":
            return "#270E41";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    statusShow(item) {
      const status = item.polizeStatus ? item.polizeStatus : item.status;
      if (status) {
        const st = PolicyStatus[status];
        return st;
      }

      return "-";
    },

    getAgent(value) {
      if (value.createdBy) {
        return value.createdBy.fullname ? value.createdBy.fullname : "-";
      }
      return "-";
    },

    gotosetStatus(item, stat) {
      this.setStatusObject = {
        dialog: true,
        value: stat,
        item,
        loading: false,
        note: "",
      };
    },
    cancelSetStatus() {
      this.setStatusObject = {
        dialog: false,
        value: null,
        item: null,
        loading: false,
        note: "",
      };
    },

    setStatus() {
      this.setStatusObject.loading = true;
      getAPI
        .put(`/patient/updatePatient/${this.setStatusObject.item.uuid}`, {
          polizeStatus: this.setStatusObject.value.value,
          noteForStatusChange: this.setStatusObject.note,
        })
        .then(() => {
          notifyInfo(this.$t("Status has been updated"));
          this.setStatusObject.loading = false;
          this.cancelSetStatus();
          this.getPatients();
        })
        .catch((error) => {
          this.setStatusObject.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    colorPolicy(value) {
      const status = value;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },

    exportClients() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      const date = moment().format("MM/DD/YYYY HH:mm");

      const name = this.getpatientN + "s-" + date + ".xlsx";
      this.donwloading = true;
      let body = Object.assign({}, this.toExport);
      if (this.checkToExport.length != 0) {
        body = { ...body, exportOnly: this.checkToExport };
      }

      const url = apiBaseUrl + "/patient/filterPatients?export=true";
      axios({
        url: url,
        method: "POST",
        responseType: "blob",
        headers: auth,
        data: body,
      })
        .then((res) => {
          this.donwloading = false;
          const file_path = URL.createObjectURL(res.data);
          const a = document.createElement("A");
          a.href = file_path;
          a.setAttribute("download", name);
          a.setAttribute("target", "_blank");
          a.click();
          this.cancelExport();
        })
        .catch((err) => {
          this.donwloading = false;

          notifyError(
            `An error occurred while downloading, please contact an administrator`
          );
        });
    },
  },
});
</script>

<style lang="scss">
.patient-list {
  .v-data-table {
    tbody {
      tr {
        cursor: pointer !important;
      }
    }
  }
}

.swipri {
  margin-top: -1px !important;
}

.namepatient {
  position: relative;
}

.notificationdocument {
  position: absolute;
  top: -5px;
  right: 3px;
}

.toolt {
  width: 320px !important;
}

.status-active {
  color: rgb(231, 49, 49) !important;
}
</style>

<i18n>
{
  "en": {
    "Fullname": "Full name",
    "dialogTitle": "Reason why you open the details of :",
    "notification": "Successfully removed",
    "labelGender": "Gender",
    "labelHomePhone": "Phone",
    "labelEmergencyPhone": "Emergency phone"

  },
  "es": {
    "Fullname": "Nombre completo",
    "dialogTitle": "Razon por la que abre los detalles de :",
    "notification": "Eliminado con éxito",
    "labelGender": "Género",
    "labelHomePhone": "Teléfono",
    "labelEmergencyPhone": "Teléfono de emergencias"
  }
}
</i18n>
